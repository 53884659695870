import {computed, effect} from '@preact/signals-react';
import {userData} from '~/entities/user';
import {
  AIRDROP_DISTRIBUTION_DATE,
  isTestingEnvironment,
} from '~/shared/feature-flags';
import isFeatureEnabled, {getTimeWhenEnabled} from '~/shared/gradual-releaase';

const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * ONE_HOUR;

// Delay release for 1 hour for staging and 14 hours for prod
const RELEASE_START_DELAY = ONE_HOUR * (isTestingEnvironment() ? 1 : 14);
// Relase during 1 hour window for staging and 10 hours for prod
const RELEASE_END_DELAY =
  RELEASE_START_DELAY + ONE_HOUR * (isTestingEnvironment() ? 1 : 10);

const RELEASE_START = new Date(
  AIRDROP_DISTRIBUTION_DATE.getTime() + RELEASE_START_DELAY + ONE_DAY,
);

const RELEASE_END = new Date(
  AIRDROP_DISTRIBUTION_DATE.getTime() + RELEASE_END_DELAY + ONE_DAY,
);

export const isAirdropReleasedForUser = computed(() => {
  const telegramId = userData.value?.telegram_id;

  if (!telegramId) {
    return false;
  }

  return isFeatureEnabled(telegramId, RELEASE_START, RELEASE_END);
});

export const airdropReleaseTime = computed(() => {
  const telegramId = userData.value?.telegram_id;

  if (!telegramId) {
    return -1;
  }

  const readyAt = getTimeWhenEnabled(telegramId, RELEASE_START, RELEASE_END);

  return readyAt;
});
