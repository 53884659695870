import {useLaunchParams} from '@telegram-apps/sdk-react';

const ADMINS = [7027088735];
export function useHasAdminAccess() {
  const params = useLaunchParams();

  const telegramId = params.initData?.user?.id;

  if (!telegramId) {
    return false;
  }

  return ADMINS.includes(telegramId);
}
