import {Box} from '~/components/kit/box';
import {Typography} from '~/components/kit/typography';
import {BlackHoleRankChip} from '~/entities/earn/black-hole/ui/black-hole-rank-chip';
import {getBlackHoleRank} from '~/entities/earn/black-hole/black-hole-ranks';
import {rem} from '~/shared/utils/style-helpers';
import {RewardValue, useAppTheme} from '~/components/kit';
import {RokeTokenLogo} from '~/entities/earn/roke-tokens';
import starBackground from './assets/balance-card-stars-bg.png';
import {ImgTransition} from '~/components/kit/img-transition';
import {Button} from '~/components/kit/button/button';
import {RokeTokens} from '~/entities/roke-tokens';
import {useSignals} from '@preact/signals-react/runtime';
import {
  airdropReleaseTime,
  isAirdropReleasedForUser,
} from '~/features/airdrops/feature-flag';
import {useModals} from '~/components/kit/modal';
import {Airdrops, useAirdropModel} from '~/entities/airdrops';
import {AirdropModal} from '~/features/airdrops/airdrop-modal';
import {AIRDROP_MODAL_NAME} from '../airdrop-modal';
import React from 'react';
import {formatCountdownWords} from '~/shared/utils/format/format-time';
import {formatNumber} from '~/shared/utils/format/format-numbers';
import {BlackHoleAirdropPendingAd} from '~/features/ads/black-hole-airdrop-pending-ad';
import {Crystals} from '~/entities/crystals';

export function RokeTokenBalanceCard({
  blackHoleSize,
  ...rest
}: {
  blackHoleSize: number;
} & React.HTMLAttributes<HTMLDivElement>) {
  useSignals();
  const rank = getBlackHoleRank(blackHoleSize);
  const theme = useAppTheme();
  const modals = useModals();

  const isReleasedForUser = isAirdropReleasedForUser.value;
  // TODO: Enable airdrop
  const airdropModel = useAirdropModel(Airdrops.SecondSeason);
  const tokenBalance = airdropModel.airdrop.data.amount;
  const usdValue = formatNumber(RokeTokens.toFloat(tokenBalance));

  const crystalAmount = airdropModel.airdrop.data.crystal_amount;
  const airdropAmount = airdropModel.airdrop.data.amount;

  // if (!isReleasedForUser) {
  //   return <BlackHoleAirdropPendingAd />;
  // }

  return (
    <React.Fragment>
      {!airdropModel.isAirdropPending && (
        <AirdropModal
          airdropName={Airdrops.SecondSeason}
          crystalAmount={airdropModel.airdrop.data.crystal_amount}
          seasonRank={airdropModel.airdrop.data.rank}
          airdropAmount={airdropModel.airdrop.data.amount}
          isClaimed={airdropModel.isClaimed}
        />
      )}
      <Box
        bgImage={
          <ImgTransition
            src={starBackground}
            css={{
              width: '60%',
              right: '5%',
              top: '5%',
              position: 'absolute',
            }}
          />
        }
        backgroundColor="#0F061F"
        css={{
          padding: rem(20),
        }}
        radius={30}
        {...rest}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography.body
            css={{
              color: theme.colors.onSurfaceAlternativeSecondary,
              fontSize: theme.fontSize.s7,
            }}
          >
            Singularity Balance
          </Typography.body>
          <BlackHoleRankChip rank={rank} />
        </div>

        <div
          css={{
            paddingTop: rem(8),
            display: 'flex',
            flexDirection: 'column',
            gap: rem(10),
          }}
        >
          {airdropAmount ? (
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography.h1
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <RewardValue
                  rewardType="roketo"
                  value={RokeTokens.format(airdropAmount, {
                    precision: 0,
                  })}
                  css={{
                    color: theme.colors.onSurfaceAlternative,
                  }}
                />

                {/* <RokeTokenLogo
              css={{
                marginLeft: rem(6),
              }}
            /> */}
              </Typography.h1>
              {/* <Typography.h6>
            ≈ $
            {RokeTokens.format(airdropAmount, {
              precision: 2,
            })}
          </Typography.h6> */}
            </div>
          ) : null}

          {crystalAmount ? (
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography.h1
                css={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {/* {Crystals.format(crystalAmount, {
              precision: 3,
            })} */}
                <RewardValue
                  rewardType="crystal"
                  value={
                    '+' +
                    Crystals.format(crystalAmount, {
                      precision: 0,
                    })
                  }
                  css={{
                    color: theme.colors.onSurfaceAlternative,
                  }}
                />
              </Typography.h1>
              {/* <Typography.h6>
            ≈ $
            {RokeTokens.format(airdropAmount, {
              precision: 2,
            })}
          </Typography.h6> */}
            </div>
          ) : null}
          {/* <div
            css={{
              color: theme.colors.onSurfaceAlternativeSecondary,
              fontSize: theme.fontSize.s6,
              marginTop: rem(4),
            }}
          >
            ≈ ${usdValue}
          </div> */}
        </div>

        {airdropModel.hasNoAirdrop ? (
          <Button
            color="purple"
            isDisabled
            isFlat
            css={{
              marginTop: rem(20),
              width: '100%',
              filter: isReleasedForUser ? 'grayscale(0)' : 'grayscale(0.5)',
            }}
          >
            No airdrop for you 🥺
          </Button>
        ) : (
          <Button
            color="purple"
            css={{
              marginTop: rem(20),
              width: '100%',
              filter: isReleasedForUser ? 'grayscale(0)' : 'grayscale(0.5)',
            }}
            isFlat
            isDisabled={!isReleasedForUser}
            onClick={() => {
              isReleasedForUser && modals.open(AIRDROP_MODAL_NAME);
            }}
          >
            {airdropModel.isClaimed
              ? 'Share Result'
              : isReleasedForUser
              ? 'Get Rewards'
              : `Your drop in ${formatCountdownWords(
                  new Date(airdropReleaseTime.value),
                  true,
                )}`}
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
