import {analyticsAdapter} from '~/shared/analytics';

export function trackClaimReward({
  rewardType,
  amount,
}: {
  rewardType: string;
  amount: string | number;
}) {
  analyticsAdapter.track(
    'Cosmic Case: claim reward',
    {
      reward_type: rewardType,
      amount,
    },
    {
      isImportant: true,
    },
  );
}
