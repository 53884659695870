const DECIMALS = 2;
const DECIMALS_MP = 10 ** DECIMALS;

const lunarToFloat = (value: string | number) => {
  return Number(value) / DECIMALS_MP;
};

const floatToLunar = (value: string | number) => {
  return Math.round(Number(value) * DECIMALS_MP);
};

const lunar = (
  value: string | number,
  {
    isSteady = false,
    precision,
  }:
    | {
        isSteady?: boolean;
        precision?: number;
      }
    | undefined = {},
): string => {
  let v;
  if (isSteady) {
    v = (Number(value) / DECIMALS_MP).toString();
  } else {
    v = Number(Number(value) / DECIMALS_MP).toString();
  }

  if (typeof precision !== 'undefined') {
    v = Number(v).toFixed(precision);
  }
  return v;
};

export const Crystals = {
  toFloat: lunarToFloat,
  toInt: floatToLunar,
  format: lunar,
};
