import {IAuthorizationResponse} from '~/api';
import {apiClient} from '~/api/shared/api-client';

export type ICreateInvoiceResponse = {
  invoice_link: string;
};

function getInvoiceLink(
  token: IAuthorizationResponse,
  {
    productId,
  }: {
    productId: number;
  },
) {
  return apiClient.post<ICreateInvoiceResponse>(
    '/payments/create-invoice-link/',
    {
      product_id: productId,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

interface IProduct {
  title: string;
  description: string;
  label: string;
  stars_amount: number;
  crystal_amount: number;
  price_usd: number;
  id: number;
}

function getProducts(token: IAuthorizationResponse) {
  return apiClient
    .get<IProduct[]>('/products/', {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    })
    .then((res) => {
      res.data.sort((a, b) => a.crystal_amount - b.crystal_amount);
      return res;
    });
}

export const PurchaseCrystalsApi = {
  getInvoiceLink,
  getProducts,
};
