import {Button} from '~/components/kit/button/button';
import {useModals} from '~/components/kit/modal';
import {PURCHASE_CRYSTALS_MODAL_NAME} from '../lib';
import {RewardValue} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';
import {trackCrystalPurchaseModalOpen} from '../analytics';

export function PurchaseCrystalsButton() {
  const modals = useModals();

  const handleClick = () => {
    trackCrystalPurchaseModalOpen();
    modals.open(PURCHASE_CRYSTALS_MODAL_NAME);
  };

  return (
    <Button
      color="purple-gradient"
      onClick={handleClick}
      css={{
        width: '100%',
      }}
      size="small"
    >
      Buy More
      <RewardValue
        value={''}
        rewardType="crystal"
        css={{
          marginLeft: rem(4),
        }}
      />
    </Button>
  );
}
