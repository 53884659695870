export const EnvironmentVariables: {
  API_BASE_URL: string;
  MIXPANEL_TOKEN: string;
  SENTRY_DSN: string;
  isDevelopment: boolean;
  isStaging: boolean;
  MINIAPP_URL: string;
  BOT_URL: string;
  APP_ENV: 'dev' | 'production' | 'local-dev' | 'staging';
  REWARD_TWITTER_POST_ID: string;
  ANALYTICS_ECO_MODE: boolean;
  MAINTENANCE: boolean;
  ADS_GRAM_ID: string;
} = {
  API_BASE_URL: import.meta.env.VITE_BASE_API_URL,
  MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  isDevelopment: ['local-dev', 'dev'].includes(import.meta.env.VITE_APP_ENV),
  isStaging: ['staging'].includes(import.meta.env.VITE_APP_ENV),
  MINIAPP_URL: import.meta.env.VITE_MINIAPP_URL,
  BOT_URL: import.meta.env.VITE_BOT_URL,
  APP_ENV: import.meta.env.VITE_APP_ENV,
  REWARD_TWITTER_POST_ID: import.meta.env.VITE_REWARD_TWITTER_POST_ID,
  ANALYTICS_ECO_MODE: import.meta.env.VITE_ANALYTICS_ECO_MODE === 'true',
  MAINTENANCE: import.meta.env.VITE_APP_MAINTENANCE === 'true',
  ADS_GRAM_ID: import.meta.env.VITE_APP_ADSGRAM_BLOCK_ID,
};
