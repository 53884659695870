import {useMutation, useQuery} from '@tanstack/react-query';
import {Button} from '~/components/kit/button/button';
import {
  Modal,
  ModalClose,
  ModalControlButton,
  useModals,
} from '~/components/kit/modal';
import {PurchaseCrystalsApi} from '../api/purchase-crystals-api';
import {useAuthorizationToken} from '~/entities/user';
import {useDockModel} from '~/entities/dock';
import {invoice} from '@telegram-apps/sdk';
import {Card, RewardValue, useAppTheme} from '~/components/kit';
import {ModalHeader} from '~/components/kit/modal/header';
import {rem} from '~/shared/utils/style-helpers';
import {StarIcon} from '../assets/star';
import crystalPurchaseBg from '../assets/crystal-purchase-bg.png';
import {ImgTransition} from '~/components/kit/img-transition';
import overButtonCrystalSvg from '../assets/over-button-crystal.svg';
import {ArrowRightIcon} from '~/components/kit/icons/arrow-right-icon';
import {
  PURCHASE_CRYSTALS_MODAL_NAME,
  PURCHASE_SUCCESS_MODAL_NAME,
} from '../lib';
import {crystalsPurchaseStore} from '../model';
import {useSignals} from '@preact/signals-react/runtime';
import {
  toFloatCrystals,
  toFloatUSD,
} from '~/shared/utils/format/format-numbers';
import {
  trackCrystalAmountSelect,
  trackCrystalPurchaseComplete,
  trackCrystalPurchaseStart,
} from '../analytics';

//  "https://t.me/$I5-AyeTxcVPSAgAAMPAQzarTS9w" => "I5-AyeTxcVPSAgAAMPAQzarTS9w"
const getInvoiceSlug = (invoiceLink: string) => {
  return invoiceLink.split('/').pop()?.slice(1) || '';
};

export function PurchaseCrystalsModal() {
  useSignals();

  const theme = useAppTheme();
  const dock = useDockModel();
  const modals = useModals();
  const token = useAuthorizationToken();

  const productsQuery = useQuery({
    queryKey: ['crystal-products'],
    queryFn: () => PurchaseCrystalsApi.getProducts(token),
    staleTime: 1000 * 60 * 60 * 24,
  });

  const purchaseMutation = useMutation({
    mutationKey: ['purchase-crystals'],
    mutationFn: async (productId: number) => {
      const product = productsQuery.data?.data.find((p) => p.id === productId);
      if (product) {
        trackCrystalPurchaseStart({
          amount: product.crystal_amount,
          price: product.price_usd,
          stars: product.stars_amount,
        });
      }

      const link = await PurchaseCrystalsApi.getInvoiceLink(token, {
        productId,
      });

      const slug = getInvoiceSlug(link.data.invoice_link);
      const status = await invoice.open(slug);

      if (status === 'paid') {
        dock.dockQuery.refetch();
        if (product) {
          crystalsPurchaseStore.setLastPurchaseAmount(product.crystal_amount);
          trackCrystalPurchaseComplete({
            amount: product.crystal_amount,
            price: product.price_usd,
            stars: product.stars_amount,
            success: true,
          });
        }
        modals.open(PURCHASE_SUCCESS_MODAL_NAME, {
          replace: true,
        });
        modals.closeExact(PURCHASE_CRYSTALS_MODAL_NAME);
      } else if (product) {
        trackCrystalPurchaseComplete({
          amount: product.crystal_amount,
          price: product.price_usd,
          stars: product.stars_amount,
          success: false,
        });
      }

      return link;
    },
  });

  const products = productsQuery.data?.data || [];

  return (
    <Modal
      bgImage={
        <ImgTransition
          src={crystalPurchaseBg}
          css={{minWidth: '100%', mixBlendMode: 'difference'}}
        />
      }
      backgroundColor="#3D0099"
      name={PURCHASE_CRYSTALS_MODAL_NAME}
      controlButton={
        <div
          css={{
            marginTop: rem(25),
            display: 'flex',
            gap: rem(12),
          }}
        >
          <ModalControlButton
            color="blue"
            onClick={() => {
              modals.closeExact(PURCHASE_CRYSTALS_MODAL_NAME);
            }}
          >
            <ArrowRightIcon
              css={{
                transform: 'rotate(180deg)',
                marginRight: rem(8),
              }}
            />
            Back
          </ModalControlButton>
          <ModalClose />
        </div>
      }
    >
      <ModalHeader
        title={'Buy Crystals'}
        css={{
          color: theme.colors.onSurfaceAlternative,
        }}
      ></ModalHeader>

      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: rem(12),
          marginBottom: rem(20),
        }}
      >
        {products.map((product) => (
          <BuyCrystalButton
            key={product.id}
            amount={product.crystal_amount}
            stars={product.stars_amount}
            price={product.price_usd}
            onClick={(amount) => {
              crystalsPurchaseStore.setSelectedAmount(amount);
              crystalsPurchaseStore.setSelectedProductId(product.id);
            }}
          />
        ))}
      </div>

      <Button
        isDisabled={!crystalsPurchaseStore.selectedAmount}
        color="purple-gradient"
        onClick={() => {
          const productId = crystalsPurchaseStore.selectedProductId.value;
          if (productId) {
            purchaseMutation.mutate(productId);
          }
        }}
        css={{
          width: '100%',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <ImgTransition
          src={overButtonCrystalSvg}
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'translate(30%, -60%) rotate(5deg)',
          }}
        ></ImgTransition>
        Buy&nbsp;
        {crystalsPurchaseStore.selectedAmount && (
          <RewardValue
            value={toFloatCrystals(crystalsPurchaseStore.selectedAmount.value)}
            rewardType="crystal"
            reversed
          ></RewardValue>
        )}
        &nbsp;Crystals
      </Button>
    </Modal>
  );
}

function BuyCrystalButton({
  amount,
  stars,
  price,
  onClick,
}: {
  amount: number;
  stars: number;
  price: number;
  onClick?: (amount: number) => void;
}) {
  const theme = useAppTheme();

  const handleClick = () => {
    trackCrystalAmountSelect({
      amount,
      price,
      stars,
    });
    onClick?.(amount);
  };

  return (
    <Card
      rounding="medium"
      shadowColor={theme.colors.onSurface}
      shadowOffsetX={2}
      shadowOffsetY={2}
      borderWidth={2}
      css={{
        paddingLeft: rem(8),
        paddingRight: rem(8),
        display: 'flex',
        userSelect: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'transform 0.2s',
        cursor: 'pointer',
        ':hover, :active': {
          transform: 'scale(1.05) translateY(-5px)',
        },
        ':active': {
          transform: 'scale(0.95) translateY(5px)',
        },
      }}
      onClick={handleClick}
    >
      <div
        css={{
          marginBottom: rem(12),
          display: 'flex',
        }}
      >
        <RewardValue
          value={toFloatCrystals(amount)}
          rewardType="crystal"
          iconSize={rem(40)}
          reversed
          css={{
            flexDirection: 'row-reverse',
            fontSize: rem(36),
          }}
        />
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          fontSize: theme.fontSize.s6,
        }}
      >
        <StarIcon />
        <span
          css={{
            fontWeight: 700,
            color: theme.colors.onSurface,
          }}
        >
          {stars}
        </span>
        &nbsp;
        <span
          css={{
            color: theme.colors.onSurfaceSecondary,
          }}
        >
          ≈${toFloatUSD(price)}
        </span>
      </div>
    </Card>
  );
}
