import {signal} from '@preact/signals-react';

export const crystalsPurchaseStore = {
  selectedAmount: signal<number>(0),
  selectedProductId: signal<number | null>(null),
  lastPurchaseAmount: signal<number>(0),
  setSelectedAmount: (amount: number) => {
    crystalsPurchaseStore.selectedAmount.value = amount;
  },
  setSelectedProductId: (id: number) => {
    crystalsPurchaseStore.selectedProductId.value = id;
  },
  setLastPurchaseAmount: (amount: number) => {
    crystalsPurchaseStore.lastPurchaseAmount.value = amount;
  },
  reset: () => {
    crystalsPurchaseStore.selectedAmount.value = 0;
    crystalsPurchaseStore.selectedProductId.value = null;
    crystalsPurchaseStore.lastPurchaseAmount.value = 0;
  },
};
