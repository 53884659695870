import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAuthorizationToken, useUserQuery} from '~/entities/user';
import {DropResponseType, OpenCaseApi} from './api/cosmic-case-api';
import {useDockModel} from '~/entities/dock';
import {RewardType} from '~/entities/rewards';
import {trackClaimReward} from './analytics';

export const COSMIC_CASE_PRICE = 100;
export const DELAY_BETWEEN_KICKS = 300;
export const FINAL_KICK_IDX = 4;

export const mapDropTypeToRewardType: Record<DropResponseType, RewardType> = {
  crystals: 'crystal',
  'lunar-loot': 'lunar',
  'roketo-tokens': 'roketo',
};

export function useCosmicCaseModel() {
  const token = useAuthorizationToken();
  const queryClient = useQueryClient();
  const dockModel = useDockModel();
  const dockQuery = dockModel.dockQuery;
  const userQuery = useUserQuery();

  const claimCase = useMutation({
    mutationKey: ['cosmic-case'],
    mutationFn: () => {
      return OpenCaseApi.openCase(token);
    },

    onSuccess: (data) => {
      trackClaimReward({
        rewardType: data.data.drop_type,
        amount: data.data.amount,
      });

      queryClient.setQueryData(['cosmic-case'], data);
      dockQuery.refetch();
      userQuery.refetch();
    },
  });

  const data = claimCase.data?.data;
  const rewardType = data ? mapDropTypeToRewardType[data.drop_type] : 'lunar';

  return {
    rewardType,
    claimCase,
  };
}
