import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AirdropApi, UserAirdrop} from '~/api/airdrops';
import {isAllQueriesReady} from '~/shared/utils/query';
import {useAuthorizationToken} from '../user';
import {trackAirdropClaimed} from './analytics';
import {RokeTokens} from '../roke-tokens';

export const Airdrops = {
  FirstSeason: 'black-hole',
  SecondSeason: 'black-hole-2',
};

const EMPTY_AIRDROP: UserAirdrop = {
  user_id: 0,
  name: '',
  created_at: 1,
  claimed_at: null,
  amount: 0,
  crystal_amount: 0,
  rank: 0,
  id: 0,
};

export function useAirdropModel(airdropName: string) {
  const token = useAuthorizationToken();
  const queryClient = useQueryClient();

  const airdrops = useQuery({
    queryKey: ['user-airdrops'],
    queryFn: async () => {
      return AirdropApi.getUserClaimedAirdrops(token);
    },
    refetchOnMount: false,
    refetchInterval: 0,
  });

  const airdrop = useQuery({
    queryKey: ['airdrop', airdropName],
    queryFn: async () => {
      return AirdropApi.getAirdrop(airdropName, token);
    },
    refetchOnMount: false,
    refetchInterval: 0,
  });

  const claimAirdrop = useMutation({
    mutationKey: ['claim-airdrop', airdropName],
    mutationFn: async () => {
      return AirdropApi.claimAirdrop(airdropName, token);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['user-airdrops'], data);
      trackAirdropClaimed({
        airdropName,
        amount: RokeTokens.toFloat(data.data.amount),
      });
    },
  });

  const isReady = isAllQueriesReady(airdrops, airdrop);

  const isClaimed = airdrop.data?.data?.claimed_at !== null;

  // Data is loaded but airdrop is empty
  // Means no calculations happened yet
  const isAirdropPending = !!airdrop.data?.data && !('id' in airdrop.data.data);

  const hasNoAirdrop = isReady && airdrop.data?.data === null;

  return {
    airdrops: {
      query: airdrops,
      data: airdrops.data?.data || [],
    },
    airdrop: {
      query: airdrop,
      data: isAirdropPending
        ? EMPTY_AIRDROP
        : (airdrop.data?.data as UserAirdrop | undefined) || EMPTY_AIRDROP,
    },
    claimAirdrop,
    isReady,
    isClaimed,
    isAirdropPending,
    hasNoAirdrop,
  };
}
