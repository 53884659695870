import {analyticsAdapter} from '~/shared/analytics';

export function trackCrystalPurchaseStart({
  amount,
  price,
  stars,
}: {
  amount: number;
  price: number;
  stars: number;
}) {
  analyticsAdapter.track(
    'Crystals: start purchase',
    {
      amount,
      price_usd: price,
      stars,
    },
    {
      isImportant: true,
    },
  );
}

export function trackCrystalPurchaseComplete({
  amount,
  price,
  stars,
  success,
}: {
  amount: number;
  price: number;
  stars: number;
  success: boolean;
}) {
  analyticsAdapter.track(
    'Crystals: complete purchase',
    {
      amount,
      price_usd: price,
      stars,
      success,
    },
    {
      isImportant: true,
    },
  );
}

export function trackCrystalPurchaseModalOpen() {
  analyticsAdapter.track(
    'Crystals: open purchase modal',
    {},
    {
      isImportant: true,
    },
  );
}

export function trackCrystalAmountSelect({
  amount,
  price,
  stars,
}: {
  amount: number;
  price: number;
  stars: number;
}) {
  analyticsAdapter.track('Crystals: select amount', {
    amount,
    price_usd: price,
    stars,
  });
}
