import {
  ServerTimestamp,
  toClientTimestamp,
} from '~/shared/utils/format/format-time';
import {DailyMissionApi, IDailyMissionStats} from './api/daily-mission-api';
import {IDockInfoResponse} from '~/api/dock';
import {useQueryClient, useQuery, useMutation} from '@tanstack/react-query';
import {makeAxiosResponse} from '~/api/shared/api-client';
import {useDockModel} from '~/entities/dock';
import {useAuthorizationToken} from '~/entities/user';
import {isQueryReady} from '~/shared/utils/query';
import {trackDailyMissionClaim} from './analytics';
import {getDailyRewardsArray} from './lib';

enum RewardType {
  CRYSTAL = 'crystal',
  LUNAR = 'lunar',
}

export interface DailyReward {
  type: RewardType;
  amount: number;
  claimed?: boolean;
}

const EMPTY_DAILY_STATS: IDailyMissionStats = {
  claimed_at: 0 as ServerTimestamp,
  streak_days: 0,
  streak_top_percent: 0,
  can_claim_at: 0 as ServerTimestamp,
};

const DAILY_REWARDS_SEQUENCE: RewardType[] = [
  RewardType.LUNAR,
  RewardType.CRYSTAL,
  RewardType.LUNAR,
  RewardType.CRYSTAL,
  RewardType.LUNAR,
  RewardType.LUNAR,
  RewardType.CRYSTAL,
];

class DailyMissionCalculator {
  private readonly MAX_DISPLAYED_CHECKS = 5;
  private readonly MAX_STREAK_DAYS = 7;
  private readonly LUNAR_MULTIPLIERS = [0.5, 0.75, 1, 1.5, 2, 2.5, 3];
  private readonly CRYSTAL_REWARDS = [0, 1000, 0, 1500, 0, 0, 2000];
  constructor(
    private statsData: IDailyMissionStats,
    private dockData: IDockInfoResponse,
  ) {}

  canClaim(): boolean {
    return toClientTimestamp(this.statsData.can_claim_at) < Date.now();
  }

  private getLunarRewardsArray(speed: number): number[] {
    return this.LUNAR_MULTIPLIERS.map(
      (multiplier) => multiplier * speed * 3600,
    );
  }

  private getCrystalReward(streakDay: number): number {
    const day = streakDay % 7 || 7; // Convert to 1-7 range

    return this.CRYSTAL_REWARDS[day];
  }

  private getRewardTypeForDay(day: number): RewardType {
    return DAILY_REWARDS_SEQUENCE[day % DAILY_REWARDS_SEQUENCE.length];
  }

  private getLunarRewardForDay(day: number): number {
    const lunarRewards = this.getLunarRewardsArray(
      this.dockData.combined_lunar_loot_speed,
    );
    return day >= this.MAX_STREAK_DAYS
      ? lunarRewards[lunarRewards.length - 1]
      : lunarRewards[day];
  }

  getLastReward(): DailyReward {
    if (this.statsData.streak_days === 0) {
      return {type: DAILY_REWARDS_SEQUENCE[0], amount: 0};
    }

    const previousDay = this.statsData.streak_days - 1;
    const rewardType = this.getRewardTypeForDay(previousDay);

    return {
      type: rewardType,
      amount:
        rewardType === RewardType.CRYSTAL
          ? this.getCrystalReward(previousDay)
          : this.getLunarRewardForDay(previousDay),
    };
  }

  getNextReward(): DailyReward {
    const currentDay = this.statsData.streak_days;
    const rewardType = this.getRewardTypeForDay(currentDay);

    return {
      type: rewardType,
      amount:
        rewardType === RewardType.CRYSTAL
          ? this.getCrystalReward(currentDay)
          : this.getLunarRewardForDay(currentDay),
    };
  }

  getDisplayedChecks(): number {
    return Math.min(this.statsData.streak_days, this.MAX_DISPLAYED_CHECKS);
  }

  getUpcomingRewards(count: number = this.MAX_STREAK_DAYS): DailyReward[] {
    const startDay = Math.max(
      0,
      this.statsData.streak_days - this.MAX_DISPLAYED_CHECKS,
    );

    return Array(count)
      .fill(0)
      .map((_, index) => {
        const day = startDay + index;
        const rewardType = this.getRewardTypeForDay(day);
        const isAlreadyClaimed = day < this.statsData.streak_days;

        return {
          type: rewardType,
          amount:
            rewardType === RewardType.CRYSTAL
              ? this.getCrystalReward(day)
              : this.getLunarRewardForDay(day),
          claimed: isAlreadyClaimed,
        };
      });
  }
}

export function useDailyMission() {
  const token = useAuthorizationToken();
  const dock = useDockModel();
  const queryClient = useQueryClient();

  const statsQuery = useQuery({
    queryKey: ['daily-mission-stats'],
    queryFn: () => DailyMissionApi.getDailyMissionStats(token),
  });

  const statsData =
    statsQuery.data?.data || makeAxiosResponse(EMPTY_DAILY_STATS).data;
  const claimQuery = useMutation({
    mutationKey: ['daily-mission-claim'],
    mutationFn: () => DailyMissionApi.claimDailyMission(token),
    onSuccess: (data) => {
      dock.dockQuery.refetch();

      queryClient.setQueryData(['daily-mission-stats'], data);
      trackDailyMissionClaim(data.data);
    },
  });

  const dailyRewardsArray = getDailyRewardsArray(
    dock.dock.combined_lunar_loot_speed,
  );

  const model = new DailyMissionCalculator(statsData, dock.dock);

  return {
    isReady: isQueryReady(statsQuery),
    statsQuery,
    statsData,
    claimQuery,
    dailyRewardsArray,
    model,
  };
}
